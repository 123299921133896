import React, { useState } from 'react';
import * as yup from 'yup';
import Spinner from 'react-spinkit';
import { Link } from 'gatsby';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import FormField from '../form/formField';
import CheckInput from '../checkInput/checkBox';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../actions/authActions';
import './style.scss';
import { TYPE_LOG_IN } from '../../store/reducer';

const translations = {
  eng: {
    loginTitle: 'Login to your account',
    username: 'Username',
    password: 'Password',
    rememberMe: 'Remember Me',
    lostPass: 'Lost your password?',
    login: 'Login',
    dontHaveAcc: "Don't have an account?",
    register: 'Click to register',
  },
  esp: {
    loginTitle: 'Ingrese a su cuenta',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    rememberMe: 'Recuérdame',
    lostPass: '¿Perdiste tu contraseña?',
    login: 'El Login',
    dontHaveAcc: '¿No tienes una cuenta?',
    register: 'Haga clic para registrarse',
  },
};

const validator = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
  remember: yup.boolean(),
});

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { language } = useSelector((state) => state);
  const handleSubmit = async (form) => {
    setLoading(true);
    setError(null);
    const response = await login(form.username, form.password);
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: TYPE_LOG_IN, payload: data });
    } else {
      const data = await response.json();
      setError('Username or password incorrect!');
    }
    setLoading(false);
  };

  return (
    <div className="bg-white login-modal-container-modified">
      <div className="login-title dsp-flx flx-center mb-4">
        <p className="fnt-evolventa fnt-r-26 bold fnt-primary">{translations[language].loginTitle}</p>
      </div>
      <Formik
        validationSchema={validator}
        onSubmit={handleSubmit}
        initialValues={{
          username: '',
          password: '',
          remember: false,
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <FormField
              required
              name="username"
              label={translations[language].username}
              onChange={handleChange}
              value={values.username}
              className="mb-18"
              containerClass="form-control-text"
              titleClass="bold"
            />
            <FormField
              required
              onChange={handleChange}
              name="password"
              label={translations[language].password}
              value={values.password}
              type="password"
              className="mb-18"
              titleClass="bold"
              containerClass="form-control-text"
            />
            {error && <p className="error-message">{error}</p>}
            <div className="login-bottom-container">
              <div className="dsp-flx ">
                <CheckInput
                  id="privacy-check"
                  name="privacy-check"
                  checkState={values.remember}
                  onCheck={(state) => {
                    setFieldValue('remember', state, true);
                  }}
                />
                <p className="">{translations[language].rememberMe}</p>
              </div>
              <div>
                <Link to="/forgotpassword">{translations[language].lostPass}</Link>
              </div>
            </div>
            <button disabled={loading} className="login-confirm bg-prime p-3 fnt-white dsp-flx flx-center btn-primary">
              <p className="m-0 slide-text-anim">{translations[language].login}</p>
              {loading ? <Spinner name="circle" color="white" size={18} style={{ marginLeft: 15 }} /> : null}
            </button>
          </Form>
        )}
      </Formik>
      <div className="register-reminder">
        <p className="mr-2">{translations[language].dontHaveAcc}</p>
        <Link to="/signup">{translations[language].register} </Link>
      </div>
    </div>
  );
};
export default LoginScreen;
