import React from 'react';
import { Form } from 'react-bootstrap';
import { BsDot } from 'react-icons/bs';

const FormField = (props) => {
  const {
    required,
    value,
    name,
    label,
    onChange,
    containerClass,
    titleClass,
    className,
    as = false,
    options = [],
    type = 'text',
    disabled = false,
  } = props;

  return (
    <div className={`dsp-flx flx-col flx-1 ${className}`}>
      <div className="dsp-flx flx-row">
        <p className={`${titleClass} mb-1 fnt-poppins fnt-black bold`}>{label}</p>
        <span className="fnt-primary carat">{required ? <BsDot size={36} /> : ''}</span>
      </div>
      {as ? (
        <Form.Control
          as={as}
          name={name}
          label={label}
          onChange={onChange}
          className={containerClass}
          disabled={disabled}
        >
          <option>--</option>
          {options.map((opt, index) => (
            <option value={Object.keys(opt)[0]} key={`option-${index}`}>
              {Object.values(opt)[0]}
            </option>
          ))}
        </Form.Control>
      ) : (
        <Form.Control
          name={name}
          label={label}
          value={value}
          type={type}
          autoComplete="off"
          onChange={onChange}
          className={containerClass}
          disabled={disabled}
        />
      )}
    </div>
  );
};
export default FormField;
