import { REMOTE_URL } from '../common/api';

export const getSuperToken = async () => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  const username = process.env.GATSBY_SUPER_USER_ID;
  const password = process.env.GATSBY_SUPER_PASSWORD;
  try {
    const response = await fetch(
      `${REMOTE_URL}/wp-json/jwt-auth/v1/token/user?user_id=${username}&password=${password}`,
      requestOptions
    );

    return response.json();
  } catch (error) {
    return error;
  }
};
