import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { BiUser } from 'react-icons/bi';
import { Navbar, Button, Nav, NavDropdown } from 'react-bootstrap';
import { isEmpty, isEqual } from 'lodash';
import ReactCountryFlag from 'react-country-flag';
import Logo from './logo';
import { getSuperToken } from '../../actions/header';
import { TYPE_SET_LANGUAGE, TYPE_LOG_OUT, TYPE_SUPER_TOKEN } from '../../store/reducer';
import { $CombinedState } from 'redux';

const langTitles = {
  eng: 'English',
  esp: 'Spanish',
};

const translations = {
  eng: {
    audioBook: 'Audio Book',
    books: 'Books',
    onlineSample: 'Online Sample',
    login: 'Login',
    createAcc: 'Member Login',
    edit_profile: 'Edit profile',
    membership: 'Membership',
    log_out: 'Log out',
  },
  esp: {
    audioBook: 'Audio libro',
    books: 'Libro',
    onlineSample: 'Muestra en línea',
    login: 'El Login',
    createAcc: 'Acceso de miembro',
    edit_profile: 'Editar perfil',
    membership: 'Afiliación',
    log_out: 'Cerrar sesión',
  },
};

const NavBootStarp = ({ loggedIn, auth, books, language, langChoices, setLogin, booksEsp, ...props }) => {
  const dispatch = useDispatch();

  const membership = auth.memberships && auth.memberships.length && auth.memberships[0].membership;

  useEffect(() => {
    async function getToken() {
      const response = await getSuperToken();
      dispatch({ type: TYPE_SUPER_TOKEN, payload: response.token });
    }
    getToken();
  }, []);

  const bookList = language === 'eng' ? books : booksEsp;

  return (
    <Navbar collapseOnSelect expand="lg" sticky="top" className="w-100">
      <Link to={loggedIn ? (language === 'eng' ? '/profile' : '/es/profile') : '/'}>
        <Navbar.Brand className="custom-brand">
          <Logo />
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="custom-navbar" id="responsive-navbar-nav">
        {loggedIn ? (
          <>
            <Nav className="me-auto"></Nav>
            <Nav>
              <NavDropdown title={translations[language].books} id="book-list">
                {bookList.map((book, idx) => (
                  <NavDropdown.Item
                    key={`book-${idx}`}
                    as={Link}
                    to={language === 'eng' ? `/${book.slug}` : `/es/${book.slug}`}
                  >
                    {book.title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown
                title={
                  <div className="dsp-iflx">
                    <ReactCountryFlag
                      countryCode={language === 'eng' ? 'US' : 'ES'}
                      svg
                      style={{
                        width: '1.5em',
                        height: '1.5em',
                      }}
                    />
                    <p className="mb-0 ml-2 fnt-poppins fnt-bold ">{langTitles[language]}</p>
                  </div>
                }
                id="lang-list"
              >
                {langChoices.map((lang) => (
                  <NavDropdown.Item
                    key={lang}
                    onSelect={() => {
                      // TODO destroy persisted data.
                      dispatch({ type: TYPE_SET_LANGUAGE, payload: lang });

                      if (!isEqual(lang, language)) {
                        navigate(props.translatedUrl);
                      }
                    }}
                  >
                    <div className="dsp-iflx">
                      <ReactCountryFlag
                        countryCode={lang === 'eng' ? 'US' : 'ES'}
                        svg
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                        }}
                      />
                      <p className="mb-0 ml-2 fnt-poppins fnt-bold ">{langTitles[lang]}</p>
                    </div>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown
                title={
                  <div className="dsp-iflx">
                    <div className="img-container-round bg-prime dsp-iflx flx-center">
                      {isEmpty(auth.avatar) ? <BiUser size={22} color="#FFF" /> : <img src={auth.avatar} alt="" />}
                    </div>
                    <p className="mb-0 ml-2 fnt-poppins fnt-bold ">{auth.first_name}</p>
                  </div>
                }
                id="user-list"
              >
                <NavDropdown.Item
                  as={Link}
                  to={language === 'eng' ? '/profile/edit-your-profile' : '/es/profile/edit-your-profile'}
                >
                  {translations[language].edit_profile}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={language === 'eng' ? '/profile/membership' : '/es/profile/membership'}>
                  {translations[language].membership}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => dispatch({ type: TYPE_LOG_OUT })}>
                  {translations[language].log_out}
                </NavDropdown.Item>
              </NavDropdown>
              {membership === 'Free Trial' ? (
                <Nav.Link as={Link} to="/profile/membership">
                  <Button
                    className="req-btn bg-prime fnt-evolventa fnt-white fnt-14 bold border-none px-3 py-2 upgrade-btn"
                    type="submit"
                  >
                    {language === 'eng' ? 'Upgrade' : 'Mejora'}
                  </Button>
                </Nav.Link>
              ) : (
                ''
              )}
            </Nav>
          </>
        ) : (
          <>
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link>
                <Link to="/login">
                  <Button
                    className="req-btn bg-prime fnt-evolventa fnt-white fnt-14 bold border-none px-3 py-2 upgrade-btn sign-in"
                    type="button"
                  >
                    <p className="m-0 fnt-white fnt-evolventa bold">{translations[language].createAcc}</p>
                  </Button>
                </Link>
              </Nav.Link>
              <NavDropdown
                title={
                  <div className="dsp-iflx">
                    <ReactCountryFlag
                      countryCode={language === 'eng' ? 'US' : 'ES'}
                      svg
                      style={{
                        width: '1.5em',
                        height: '1.5em',
                      }}
                    />
                    <p className="mb-0 ml-2 fnt-poppins fnt-bold ">{langTitles[language]}</p>
                  </div>
                }
                id="lang-list"
              >
                {langChoices.map((lang) => (
                  <NavDropdown.Item
                    key={lang}
                    onSelect={() => {
                      dispatch({ type: TYPE_SET_LANGUAGE, payload: lang });
                    }}
                  >
                    <div className="dsp-iflx">
                      <ReactCountryFlag
                        countryCode={lang === 'eng' ? 'US' : 'ES'}
                        svg
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                        }}
                      />
                      <p className="mb-0 ml-2 fnt-poppins fnt-bold ">{langTitles[lang]}</p>
                    </div>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
          </>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};
export default NavBootStarp;
