import React from 'react';
import Modal from 'reactjs-popup';

const ModalComponent = (props) => {
  const { open, onClose } = props;

  return (
    <Modal
      modal={true}
      open={open}
      onClose={onClose}
      lockScroll
      overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.7)', overflowY: 'scroll' }}
      arrowStyle
    >
      {open ? props.children : null}
    </Modal>
  );
};
export default ModalComponent;
