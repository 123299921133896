import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';

import './styles.scss';
import './navStyle.less';
import Modal from '../modal';
import Login from '../login';
import BootStrapNavBar from './bootstrapNav';

const Header = (props) => {
  const [login, setLogin] = useState(false);
  const [books, setBooks] = useState([]);
  const [booksEsp, setBooksEsp] = useState([]);
  const { auth, flagImages, loggedIn, language, langChoices } = useSelector((state) => state);

  const dispatch = useDispatch();
  const handleOnLoginClose = () => {
    setLogin(false);
  };
  const handleLogin = (action) => {
    dispatch(action);
  };
  const data = useStaticQuery(graphql`
    query headerBooksQuery {
      allDataJson {
        edges {
          node {
            eng {
              book_id
              id
              title
              cover
              description
              name
              volume
              slug
              chapters {
                id
                title
                audio
                book
                chapter
                cover
                game1
                game2
                game3
                length
              }
            }
            esp {
              book_id
              id
              title
              slug
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const b_eng = data['allDataJson']?.edges[0]?.node['eng'] || [];
    const b_esp = data['allDataJson']?.edges[0]?.node['esp'] || [];
    setBooks(b_eng);
    setBooksEsp(b_esp);
  }, [data]);

  return (
    <>
      <div className={`header-container ${props.show ? 'show' : 'hide'}`}>
        <Modal open={login} onClose={handleOnLoginClose}>
          <Login onLogin={handleLogin} />
        </Modal>
        <BootStrapNavBar
          loggedIn={loggedIn}
          auth={auth}
          setLogin={setLogin}
          books={books}
          flagImages={flagImages}
          language={language}
          hideMiddle={props.hideMiddle}
          translatedUrl={props.translatedUrl}
          langChoices={langChoices}
          booksEsp={booksEsp}
        />
      </div>
    </>
  );
};

export default Header;
