import { REMOTE_URL } from '../common/api';

export const login = (username, password) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);

  return fetch(`${REMOTE_URL}/wp-json/jwt-auth/v1/token`, {
    method: 'POST',
    redirect: 'follow',
    body: formData,
  });
};

export const sendRecoverEmail = (email) => {
  return fetch(`${REMOTE_URL}/wp-json/bdpwr/v1/reset-password?email=${email}`, {
    method: 'POST',
  });
};

export const validateCode = async (email, code) => {
  const result = await fetch(`${REMOTE_URL}/wp-json/bdpwr/v1/validate-code?email=${email}&code=${code}`, {
    method: 'POST',
  });
  const data = await result.json();
  if (result.ok) {
    return { status: true };
  } else {
    return { status: false, message: result.message };
  }
};

export const setPassword = async (email, code, password) => {
  const result = await fetch(
    `${REMOTE_URL}/wp-json/bdpwr/v1/set-password?email=${email}&code=${code}&password=${password}`
  );
  const data = await result.json();
  if (result.ok) {
    return { status: true };
  } else {
    return { status: false, message: data.message };
  }
};
