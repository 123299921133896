import React, { useState, useEffect } from 'react';
import { useTransition, useSpring, animated } from 'react-spring';
import { FaCheck } from 'react-icons/fa';

const CheckBox = ({ clickable = true, checkState, defaultCheck = false, onCheck, error = false }) => {
  const [checked, setChecked] = useState(false);
  const transitions = useTransition([{ key: 1 }, { key: 2 }], (elem) => elem.key, {
    from: 'translate3d()',
    enter: 'translate3d()',
    leave: 'translate3d()',
  });

  useEffect(() => {
    if (defaultCheck) {
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    setChecked(checkState);
  }, [checkState]);

  const [prop, set] = useSpring(() => ({
    s: 1.5,
  }));

  return (
    <div
      className={`cursor-pointer dsp-flx ${error ? 'border-red' : ''}`}
      onClick={() => {
        if (clickable) {
          onCheck(!checked);
          setChecked(!checked);
        }
      }}
    >
      {checked ? (
        <animated.div
          style={{ transform: `scale${prop.s}` }}
          onClick={() => {
            set({ s: 1 });
          }}
        >
          <div style={styles.checked} className="dsp-flx flx-center"></div>
        </animated.div>
      ) : (
        <animated.div style={prop}>
          <div style={styles.unchecked}></div>
        </animated.div>
      )}
    </div>
  );
};

const styles = {
  checked: {
    backgroundColor: '#FC650A',
    borderRadius: 1,
    width: 18,
    height: 18,
  },
  unchecked: {
    borderRadius: 1,
    width: 18,
    height: 18,
    borderWidth: 1,
    borderStyle: 'double',
    // borderColor: 'rgba(0,0,0,0.1)',
    border: '2px solid #BDBDBD',
    backgroundColor: 'rgba(0,0,0,0)',
  },
};

export default CheckBox;
